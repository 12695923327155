<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form"
                 @search="search"
                 @select="select"></search-form>
    <!-- 列表 -->
    <table-list :datas="searchVal"></table-list>

  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TableList from './component/TableList.vue'
import { getShardName, setShardName } from '@/utils/localStorage.js'
export default {
  components: {
    SearchForm,
    TableList
  },
  data() {
    return {
      options: [],
      // 表单
      form: {
        shard: null, // 分片名（默认不填）
        height: '',
        hash: '',
        timeList: [],
        startHeight: '',
        endHeight: ''
      },
      searchVal: {
        shard: null, // 分片名（默认不填）
        height: '',
        hash: '',
        timeList: [],
        startHeight: '',
        endHeight: ''
      }
    }
  },
  created() {
    if (getShardName()) {
      this.form.shard = getShardName()
      this.searchVal.shard = getShardName()
    }
  },
  mounted() {},
  methods: {
    // 分片名切换刷新
    select(value) {
      setShardName(value)
    },
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      this.searchVal = form
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep {
  .el-input__inner {
    // width: 180px !important;
  }
}
.head-title {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  margin-right: 16px;
}
.left {
  margin-left: 16px;
}
</style>
